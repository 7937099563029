import React, {useEffect, useState} from 'react'
import {
  Form,
  Drawer,
  Button,
  Space,
} from 'antd'
import {
  Col, DateField,
  MaskedField,
  Row,
  SelectField, TextField,
} from 'components'

import {DefaultOptionType} from "antd/lib/select";
import {useBusinessUnits, useClient, useProduct, useSuppliers, useUsers} from "../../../../../repositories";

type TFilterListFilterSupplierParamsForm = {
  projectIdERP: string
  clientId: string
  requesterId: string
  typeProvider: string
  supplierId: string
  supplierIdCnpj: string
  costCenterId: string
  idRequest: string
  idTotvs: string
}

const initialValues: TFilterListFilterSupplierParamsForm = {
  projectIdERP: "",
  clientId: "",
  requesterId: "",
  typeProvider: "",
  supplierId: "",
  supplierIdCnpj: "",
  costCenterId: "",
  idRequest: "",
  idTotvs: ""
}

const optionsTypeProvider: any[] = [
  {
    label: "Fornecedor",
    value: "SUPPLIER",
  },
  {
    label: "Freelancer",
    value: "FREELANCE",
  },
];

type TProps = {
  isVisibleDrawer: boolean
  setIsVisibleDrawer: React.Dispatch<boolean>,
  filters: TFiltersHook
  resetPagination: any
}

const FiltersForm = (props: TProps) => {

  const clientRepository = useClient();
  const userRepository = useUsers();
  const productRepository = useProduct();
  const buRepository = useBusinessUnits();
  const supplierRepository = useSuppliers();

  const [userOptions, setUserOptions] = useState<DefaultOptionType[]>([]);
  const [optionsClients, setClientsOptions] = useState<DefaultOptionType[]>([]);
  const [optionsBU, setBUOptions] = useState<DefaultOptionType[]>([]);
  const [optionsProduct, setProductOptions] = useState<DefaultOptionType[]>([]);


  const {
    isVisibleDrawer,
    setIsVisibleDrawer,
    filters,
    resetPagination
  } = props
  const [form] = Form.useForm<TFilterListFilterSupplierParamsForm>()

  const handleClose = () => {
    setIsVisibleDrawer(false)
  }

  const handleSubmit = async () => {
    const validatedData = await form.validateFields()
    if (!validatedData) return

    resetPagination()
    onFinish(validatedData)
    handleClose()
  }

  useEffect(() => {
    requestBUOptions()
    requestClientsOptions()
    requestUserOptions()
    requestProductOptions()
    if (!filters.items?.length) form.resetFields()
  }, [filters.items, form])

  const onFinish = (values: TFilterListFilterSupplierParamsForm) => {
    const _values = Object.entries(values)

    const filterNonNullValues = _values.filter(value => {
      const [, term] = value
      return Boolean(term)
    })
    const _filters = [] as TFilter[]
    filterNonNullValues.forEach(value => {
      const [field, term] = value
      _filters.push({ field, term: String(term) })
    })

    filters.clearAndAdd(_filters)
  }

  const onClickResetFilters = () => filters.clearAndAdd([])

  const requestUserOptions = async () => {

    const response = await userRepository.findByFilterUsers({
      page: 0,
      size: 10000,
    });
    if (!response) return;
    const mapped = response.content.map((item: any) => ({ value: item.id, label: item.name }));

    mapped.sort((a:any, b: any) => a.label.localeCompare(b.label));


    if (!response) return;

    setUserOptions(mapped);
  };

  const requestClientsOptions = async () => {

    const response = await clientRepository.findClientByFilter({
      page: 0,
      size: 10000,
    });
    if (!response) return;

    const mapped = response.content.map((item: any) => ({ value: item.id, label: item.corporateName }));
    mapped.sort((a, b) => a.label.localeCompare(b.label));


    if (!response) return;
    setClientsOptions(mapped);
  };

  const requestProductOptions = async () => {
    const response = await productRepository.findProductByFilter({
      page: 0,
      size: 10000,
    });
    if (!response) return;

    const mapped = response.content.map((item: any) => ({ value: item.id, label: item.description }));
    mapped.sort((a, b) => a.label.localeCompare(b.label));

    if (!response) return;
    setProductOptions(mapped);
  };

  const requestBUOptions = async () => {
    const response = await buRepository.findBus({
      page: 0,
      size: 10000,
    });
    if (!response) return;

    const mapped = response.content.map((item: any) => ({ value: item.id, label: item.name }));
    mapped.sort((a: { label: string; }, b: { label: string; }) => a.label.localeCompare(b.label));

    if (!response) return;
    setBUOptions(mapped);
  };

  return (
    <Drawer
        title='Filtros'
        width={500}
        onClose={handleClose}
        visible={isVisibleDrawer}
        destroyOnClose
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Row>
          <Col md={12} xl={12} xxl={12}>
            <DateField name="startDate" label="Emissão de" />
          </Col>

          <Col md={12} xl={12} xxl={12}>
            <DateField name="endDate" label="Emissão até" />
          </Col>
        </Row>
        <Row>
          <Col md={12} xl={12} xxl={12}>
            <TextField name="project" label="Nome ou ID do projeto" />
          </Col>
          <Col md={12} xl={12} xxl={12}>
            <SelectField showSearch options={optionsClients} name="clientId" label="Cliente da Requisição" optionFilterProp="label" />
          </Col>
        </Row>
        <Row>
          <Col md={12} xl={12} xxl={12}>
            <SelectField
              showSearch
              options={optionsBU}
              name="bu"
              label="Selecione a BU"
              optionFilterProp="label"
            />
          </Col>
          <Col md={12} xl={12} xxl={12}>
            <SelectField showSearch options={optionsProduct} name="productId" label="Produto" optionFilterProp="label" />
          </Col>
        </Row>
        <Row>
          <Col md={12} xl={12} xxl={12}>
            <SelectField
                showSearch
                options={userOptions}
                name="requesterId"
                label="Requisitante"
                optionFilterProp="label"
            />
          </Col>
        </Row>
        <Form.Item className='mt-1'>
          <Space size='small'>
            <Button
                onClick={handleSubmit}
                type='primary'
            >
              Filtrar
            </Button>
            <Button
                onClick={onClickResetFilters}
            >
              Limpar
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Drawer>
  )
}

export default FiltersForm
