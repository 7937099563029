import { DefaultOptionType } from 'antd/lib/select'

export enum ApiErrorCodes {
  OK = 200,
  CREATED = 201,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401
}

export enum ApiErrorMessages {
  ACCOUNT_IS_NOT_FULLY_SET_UP = 'Account is not fully set up',
  INVALID_USER_CREDENTIALS = 'Invalid user credentials',
  INVALID_CLIENT_CREDENTIALS = 'Invalid client credentials',
  USER_DISABLED = 'Account disabled',
  UNABLE_TO_LINK_USER_TO_INACTIVE_GROUP = 'Unable to link user to an inactive group',
  UNABLE_DISABLE_GROUP_HAS_USER_LINKED = 'Group has linked users, cannot delete or inactivate, please remove linked users and try again',
  USER_GROUP_ALREADY_REGISTERED = 'User group already registered for id',
  SUPPLIER_ALREADY_REGISTERED_CNPJ = 'Supplier already registered for this CNPJ',
  EMAIL_FOR_SUPPLIER_ALREADY_REGISTERED = 'Supplier email already registered!',
  CEP_NOT_FOUND_BRASIL_API = 'Todos os serviços de CEP retornaram erro.',
  INVALID_PASSWORD = 'Entered password is invalid',
  NOT_FOUND_SUPPLIER = 'Not found supplier',
  EXPIRED_PASSWORD = 'Entered password is expired',
  ADDRESS_ALREADY_LINKED_TO_SUPPLIER = 'Address already has a linked to supplier',
  ACCOUNT_BANK_ALREADY_LINKED_TO_SUPPLIER = 'Supplier already has a linked bank account',
  APPROVAL_HIERARCHY_ALREADY_REGISTERED_WITH_NAME = 'Approval hierarchy name already registered!',
  PRODUCT_WITH_ERP_ID_ALREADY_REGISTERED = 'product with erp id already registered',
  USER_ALREADY_REGISTERED_FOR_SUPPLIER = 'System user already registered for this supplier id',
  UNABLE_LINK_PRODUCTS_WITH_DIFFERENT_CATEGORIES = 'Unable to link products with different categories on the same request!',
  APPROVER_ALREADY_REGISTERED_FOR_HIERARCHY = 'Approver user id already registered for approver hierarchy!',
  THE_PERCENTAGE_SUM_IS_NOT_100 = 'The percentage sum is not 100%',
  APPROVER_ANSWER_ALREADY_INFORMED_THIS_HIERARCHY_USER = 'Approver answer\'s already informed by this hierarchy and user!',
  PURCHASE_ORDER_ITEMS_SUM_IS_NOT_EQUALS_PURCHASE_TOTAL_VALUE = 'The Purchase order items sum is not equals the purchase total value',
  ERR_TOTVS_SERVICE_INSTANCE_CANNOT_BE_NULL = 'Service Instance cannot be null.',
  APPROVAL_HIERARCHY_ALREADY_EXIST = 'Approval Hierarchy already registered for this id',
  REQUEST_VALUE_ZERO = 'Valor da requisição não pode ser zero.',
  ALREADY_EVALUATED = 'Assessment already carried out!',
  SUPPLIER_BLOCK = 'CNPJ se encontra bloqueado para o sistema, contate um Administrador.'
}

export enum StatusApproveSupplier {
  NOT_INFORMED = 'NOT_INFORMED',
  APPROVED = 'APPROVED',
  NOT_APPROVED = 'NOT_APPROVED'
}

export enum UserStatus {
  ACTIVATED = 'ACTIVATED',
  DEACTIVATED = 'DEACTIVATED'
}

export enum Status {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export enum Sort {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum MaskPhone {
  TELEPHONE = '(99) 9999-99999',
  CELLPHONE = '(99) 99999-9999',
}

export enum Role {
  ROLE = 'ROLE',
  USER = 'USER',
  GROUP = 'GROUP',
  PROFILE = 'PROFILE',
  SUPPLIER = 'SUPPLIER',
  SUPPLIER_ADDRESS = 'SUPPLIER_ADDRESS',
  SUPPLIER_CONTACT = 'SUPPLIER_CONTACT',
  SUPPLIER_ACCOUNT = 'SUPPLIER_ACCOUNT',
  SUPPLIER_APPROVE = 'SUPPLIER_APPROVE',
  SUPPLIER_ATTACHMENT = 'SUPPLIER_ATTACHMENT',
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  CATEGORY = 'CATEGORY',
  BANK = 'BANK',
  APPROVALHIERARCHY = 'APPROVALHIERARCHY',
  PRODUCT = 'PRODUCT',
  COMPANY = 'COMPANY',
  CLIENT = 'CLIENT',
  PROJECT = 'PROJECT',
  REQUEST = 'REQUEST',
  APPROVE = 'APPROVE',
  COSTCENTER = 'COSTCENTER',
}

export enum Operator {
  ADD = 'ADD',
  DELETE = 'DELETE',
  EDIT = 'EDIT',
  VIEW = 'VIEW',
  SEND = 'SEND',
  EVALUATION = 'EVALUATION',
  FISCAL = 'FISCAL',
  FINANCIAL = 'FINANCIAL',
  BUYER = 'BUYER',
  DEFAULT = 'Principal/Painel',
}

export enum OperatorLabel {
  ADD = 'Adicionar',
  DELETE = 'Excluir',
  EDIT = 'Editar',
  VIEW = 'Visualizar',
  SEND = 'Envio<br />(email/notificação)',
  EVALUATION = 'Avaliação',
  FISCAL = 'Análise<br />Fiscal',
  FINANCIAL = 'Análise<br />Financeiro',
  APPROVE = 'Aprovador',
  BUYER = 'Comprador',
}

export enum RoleOperator {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_INTEGRATION = 'ROLE_INTEGRATION',
  USER_VIEW = 'USER_VIEW',
  USER_ADD = 'USER_ADD',
  USER_EDIT = 'USER_EDIT',
  USER_DELETE = 'USER_DELETE',
  GROUP_VIEW = 'GROUP_VIEW',
  GROUP_ADD = 'GROUP_ADD',
  GROUP_EDIT = 'GROUP_EDIT',
  GROUP_DELETE = 'GROUP_DELETE',
  ROLE_VIEW = 'ROLE_VIEW',
  ROLE_ADD = 'ROLE_ADD',
  ROLE_EDIT = 'ROLE_EDIT',
  ROLE_DELETE = 'ROLE_DELETE',
  PROFILE_VIEW = 'PROFILE_VIEW',
  PROFILE_EDIT = 'PROFILE_EDIT',
  SUPPLIER_VIEW = 'SUPPLIER_VIEW',
  SUPPLIER_ADD = 'SUPPLIER_ADD',
  SUPPLIER_EDIT = 'SUPPLIER_EDIT',
  SUPPLIER_DELETE = 'SUPPLIER_DELETE',
  SUPPLIER_SEND = 'SUPPLIER_SEND',
  SUPPLIER_EVALUATION = 'SUPPLIER_EVALUATION',
  SUPPLIER_DEFAULT = 'SUPPLIER_DEFAULT',
  SUPPLIER_APPROVE_FISCAL = 'SUPPLIER_APPROVE_FISCAL',
  SUPPLIER_APPROVE_FINANCIAL = 'SUPPLIER_APPROVE_FINANCIAL',
  SUPPLIER_PRE_REGISTRATION = 'SUPPLIER_PRE_REGISTRATION',
  SUPPLIER_ACCOUNT_ADD = 'SUPPLIER_ACCOUNT_ADD',
  SUPPLIER_ACCOUNT_DELETE = 'SUPPLIER_ACCOUNT_DELETE',
  SUPPLIER_ACCOUNT_EDIT = 'SUPPLIER_ACCOUNT_EDIT',
  SUPPLIER_ACCOUNT_VIEW = 'SUPPLIER_ACCOUNT_VIEW',
  SUPPLIER_ADDRESS_ADD = 'SUPPLIER_ADDRESS_ADD',
  SUPPLIER_ADDRESS_DELETE = 'SUPPLIER_ADDRESS_DELETE',
  SUPPLIER_ADDRESS_EDIT = 'SUPPLIER_ADDRESS_EDIT',
  SUPPLIER_ADDRESS_VIEW = 'SUPPLIER_ADDRESS_VIEW',
  SUPPLIER_ATTACHMENT_ADD = 'SUPPLIER_ATTACHMENT_ADD',
  SUPPLIER_ATTACHMENT_DELETE = 'SUPPLIER_ATTACHMENT_DELETE',
  SUPPLIER_ATTACHMENT_EDIT = 'SUPPLIER_ATTACHMENT_EDIT',
  SUPPLIER_ATTACHMENT_VIEW = 'SUPPLIER_ATTACHMENT_VIEW',
  SUPPLIER_CONTACT_ADD = 'SUPPLIER_CONTACT_ADD',
  SUPPLIER_CONTACT_DELETE = 'SUPPLIER_CONTACT_DELETE',
  SUPPLIER_CONTACT_EDIT = 'SUPPLIER_CONTACT_EDIT',
  SUPPLIER_CONTACT_VIEW = 'SUPPLIER_CONTACT_VIEW',
  BANK_VIEW = 'BANK_VIEW',
  CATEGORY_VIEW = 'CATEGORY_VIEW',
  CATEGORY_ADD = 'CATEGORY_ADD',
  CATEGORY_EDIT = 'CATEGORY_EDIT',
  CATEGORY_DELETE = 'CATEGORY_DELETE',
  APPROVALHIERARCHY_VIEW = 'APPROVALHIERARCHY_VIEW',
  APPROVALHIERARCHY_ADD = 'APPROVALHIERARCHY_ADD',
  APPROVALHIERARCHY_EDIT = 'APPROVALHIERARCHY_EDIT',
  APPROVALHIERARCHY_DELETE = 'APPROVALHIERARCHY_DELETE',
  PRODUCT_VIEW = 'PRODUCT_VIEW',
  PRODUCT_ADD = 'PRODUCT_ADD',
  PRODUCT_EDIT = 'PRODUCT_EDIT',
  PRODUCT_DELETE = 'PRODUCT_DELETE',
  COMPANY_VIEW = 'COMPANY_VIEW',
  COMPANY_ADD = 'COMPANY_ADD',
  COMPANY_EDIT = 'COMPANY_EDIT',
  COMPANY_DELETE = 'COMPANY_DELETE',
  CLIENT_VIEW = 'CLIENT_VIEW',
  CLIENT_ADD = 'CLIENT_ADD',
  CLIENT_EDIT = 'CLIENT_EDIT',
  CLIENT_DELETE = 'CLIENT_DELETE',
  PROJECT_VIEW = 'PROJECT_VIEW',
  PROJECT_ADD = 'PROJECT_ADD',
  PROJECT_EDIT = 'PROJECT_EDIT',
  PROJECT_DELETE = 'PROJECT_DELETE',
  REQUEST_BUYER = 'REQUEST_BUYER',
  APPROVE_DEFAULT = 'APPROVE_DEFAULT',
  PURCHASE_ORDER_VIEW = 'PURCHASE_ORDER_VIEW',
  PURCHASE_ORDER_ADD = 'PURCHASE_ORDER_ADD',
  PURCHASE_ORDER_EDIT = 'PURCHASE_ORDER_EDIT',
  PURCHASE_ORDER_DELETE = 'PURCHASE_ORDER_DELETE',
  COSTCENTER_VIEW = 'COSTCENTER_VIEW',
  COSTCENTER_ADD = 'COSTCENTER_ADD',
  COSTCENTER_EDIT = 'COSTCENTER_EDIT',
  COSTCENTER_DELETE = 'COSTCENTER_DELETE',
  SIMPLE_ROLE_REQUESTS = 'SIMPLE_ROLE_REQUESTS',
  CREATE_REQUEST_SIMPLE = 'CREATE_REQUEST_SIMPLE',
  VIEW_REQUEST_SIMPLE = 'VIEW_REQUEST_SIMPLE',
  REQUEST_TRACKING = 'REQUEST_TRACKING',
  REQUEST_APPROVALS = 'REQUEST_APPROVALS'
}

export const excludeRoles = [
  RoleOperator.ROLE_ADMIN,
  RoleOperator.ROLE_ADD,
  RoleOperator.ROLE_EDIT,
  RoleOperator.ROLE_DELETE,
  RoleOperator.SUPPLIER_PRE_REGISTRATION,
  RoleOperator.SUPPLIER_DEFAULT,
  RoleOperator.ROLE_INTEGRATION,
]

export const operatorList = [
  {
    name: Operator.VIEW,
    label: OperatorLabel.VIEW
  },
  {
    name: Operator.ADD,
    label: OperatorLabel.ADD
  },
  {
    name: Operator.EDIT,
    label: OperatorLabel.EDIT
  },
  {
    name: Operator.DELETE,
    label: OperatorLabel.DELETE
  },
  {
    name: Operator.SEND,
    label: OperatorLabel.SEND
  },
  {
    name: Operator.EVALUATION,
    label: OperatorLabel.EVALUATION
  },
  {
    name: Operator.FISCAL,
    label: OperatorLabel.FISCAL
  },
  {
    name: Operator.FINANCIAL,
    label: OperatorLabel.FINANCIAL
  },
  {
    name: Operator.BUYER,
    label: OperatorLabel.BUYER
  }
]

export const messageUserNotAssociatedGroups
  = 'Você ainda não possui permissões de acesso ao sistema, ' +
    'contate o administrador para que o mesmo possa ' +
    'atribuir as permissões necessarias para o seu devido uso'


export enum SupplierType {
  SUPPLIER = 'SUPPLIER',
  FREELANCE = 'FREELANCE'
}
export enum nationalityType {
  BRASILEIRO = 'BRASILEIRO',
  ESTRANGEIRO = 'ESTRANGEIRO',
}

export const supplierTypeLabel = (type: string) => {
  switch (type) {
    case SupplierType.SUPPLIER: return 'Fornecedor'
    case SupplierType.FREELANCE: return 'Freelancer'
    default: return ''
  }
}

export enum SupplierStatus {
  PRE_REGISTRATION = 'PRE_REGISTRATION',
  HOMOLOGATED = 'HOMOLOGATED',
  APPROVED = 'APPROVED',
  NOT_APPROVED = 'NOT_APPROVED'
}

export const supplierStatus: Record<string, string> = {
  PRE_REGISTRATION: 'Pré - Cadastro',
  HOMOLOGATED: 'Em homologação',
  APPROVED: 'Aprovado',
  NOT_APPROVED: 'Bloqueado'
}

export enum TaxPayer {
  CONTRIBUTOR = 'CONTRIBUTOR',
  NON_CONTRIBUTOR = 'NON_CONTRIBUTOR',
  TAX_FREE = 'TAX_FREE'
}

export enum YesNo {
  YES = 'YES',
  NO = 'NO'
}

export enum StorageStepKey {
  TOKEN = 'ManagerTV1:supplier#TOKEN',
  ONE = 'ManagerTV1:supplier#ONE',
  TWO = 'ManagerTV1:supplier#TWO',
  TREE = 'ManagerTV1:supplier#TREE',
  FOUR = 'ManagerTV1:supplier#FOUR',
  FIVE = 'ManagerTV1:supplier#FIVE',
}

export const dropdownPurchaseTypeWithEmpty = [
  {
    key: '',
    value: '',
    text: 'Nenhuma opção'
  },
]

export enum StatusRequest {
  NEW = 'NEW',
  IN_QUOTATION = 'IN_QUOTATION',
  CLASSIFIED = 'CLASSIFIED',
  APPROVED = 'APPROVED',
  REFUSED = 'REFUSED',
  CANCELED = 'CANCELED',
  SENT_TO_ERP_WITH_FAILS = 'SENT_TO_ERP_WITH_FAILS',
  SENT_TO_ERP = 'SENT_TO_ERP'
}

export const statusRequestOptions: DefaultOptionType[] = [
  { value: StatusRequest.NEW, label: 'Nova' },
  { value: StatusRequest.IN_QUOTATION, label: 'Em cotação' },
  { value: StatusRequest.CLASSIFIED, label: 'Classificada' },
  { value: StatusRequest.APPROVED, label: 'Aprovada' },
  { value: StatusRequest.REFUSED, label: 'Recusada' },
  { value: StatusRequest.CANCELED, label: 'Cancelada' },
  { value: StatusRequest.SENT_TO_ERP_WITH_FAILS, label: 'Enviado parcialmente ao ERP' },
  { value: StatusRequest.SENT_TO_ERP, label: 'Enviada ao ERP' },
]

export const statusRequest: Record<string, string> = {
  NEW: 'Nova',
  IN_QUOTATION: 'Em cotação',
  CLASSIFIED: 'Classificada',
  APPROVED: 'Aprovada',
  REFUSED: 'Recusada',
  CANCELED: 'Cancelada',
  SENT_TO_ERP: 'Enviada ao ERP',
  SENT_TO_ERP_WITH_FAILS: 'Enviado parcialmente ao ERP',
}

export const statusRequestColor: Record<string, string> = {
  NEW: '#00CCFF',
  IN_QUOTATION: '#3182ce',
  CLASSIFIED: 'orange',
  APPROVED: '#019973',
  REFUSED: '#e53e3e',
  CANCELED: '#e53e3e',
  SENT_TO_ERP: 'black',
  SENT_TO_ERP_WITH_FAILS: '#ffb601',
}


export enum StatusQuote {
  WAITING_ANSWER = 'WAITING_ANSWER',
  PROPOSAL_SENT = 'PROPOSAL_SENT',
  QUOTE_REVISED = 'QUOTE_REVISED',
  WAITING_APPROVAL = 'WAITING_APPROVAL',
  FORWARD_INVOICE = 'FORWARD_INVOICE',
  PROCESS_FINISHED = 'PROCESS_FINISHED'
}

export const statusQuote: Record<string, string> = {
  WAITING_ANSWER: 'Aguardando proposta',
  PROPOSAL_SENT: 'Proposta enviada',
  QUOTE_REVISED: 'Cotação revisada',
  WAITING_APPROVAL: 'Aguardando aprovação',
  FORWARD_INVOICE: 'Encaminhar nota fiscal',
  PROCESS_FINISHED: 'Processo finalizado',
}

export const answerQuote: Record<string, string> = {
  WAITING_ANSWER: 'Aguardando Resposta',
  ACCEPT: 'Aceita',
  REFUSE: 'Recusada',
}

export enum StatusSupplierAnswer {
  WAITING_ANSWER = 'WAITING_ANSWER',
  ACCEPT = 'ACCEPT',
  REFUSE = 'REFUSE'
}

export enum StatusApproverAnswer {
  WAITING_ANSWER = 'WAITING_ANSWER',
  APPROVED = 'APPROVED',
  REFUSED = 'REFUSED'
}

export const statusApproverAnswer: Record<string, { color: string, text: string}> = {
  WAITING_ANSWER: {
    color: 'gray',
    text: 'Aguardando resposta'
  },
  APPROVED: {
    color: 'green',
    text: 'Aprovada'
  },
  REFUSED: {
    color: 'red',
    text: 'Reprovada'
  }
}
export const supplierCreationStatusOptions: DefaultOptionType[] = [
  {
    value: SupplierStatus.PRE_REGISTRATION,
    label: 'Pré-cadastro',
  }
]

export const supplierStatusOptions: DefaultOptionType[] = [
  {
    value: SupplierStatus.PRE_REGISTRATION,
    label: 'Pré-cadastro',
  },
  {
    value: SupplierStatus.HOMOLOGATED,
    label: 'Homologação',
  },
  {
    value: SupplierStatus.APPROVED,
    label: 'Aprovado',
  },
  {
    value: SupplierStatus.NOT_APPROVED,
    label: 'Reprovado',
  },
]

export const seniorityOptions: any = [
  {
    value: "JUNIOR",
    label: "JUNIOR",
  },
  {
    value: "PLENO",
    label: 'PLENO',
  },
  {
    value: "SENIOR",
    label: 'SENIOR',
  }
]

export const taxPayerOptions: DefaultOptionType[] = [
  { value: TaxPayer.CONTRIBUTOR, label: 'Contribuinte' },
  { value: TaxPayer.NON_CONTRIBUTOR, label: 'Não Contribuinte' },
  { value: TaxPayer.TAX_FREE, label: 'Isento' },
]

export enum PaymentType {
  CLIENT = 'CLIENT',
  TV1 = 'TV1'
}

export const paymentTypeOptions: DefaultOptionType[] = [
  { value: PaymentType.CLIENT, label: 'Cliente' },
  { value: PaymentType.TV1, label: 'TV1' },
]

export enum PurchaseType {
  C = 'C',
  P = 'P',
  V = 'V',
  R = 'R',
  PC = 'PC',
}

export const purchaseTypeOptions: DefaultOptionType[] = [
  { value: PurchaseType.C, label: 'Compra Normal' },
  { value: PurchaseType.P, label: 'Projeto' },
  { value: PurchaseType.V, label: 'Verba' },
  { value: PurchaseType.R, label: 'Reembolso' },
  { value: PurchaseType.PC, label: 'Prestação de Contas' },
]

export const purchaseTypes: Record<string, string> = {
  C: 'Compra Normal',
  P: 'Projeto',
  V: 'Verba',
  R: 'Reembolso',
  PC: 'Prestação de Contas',
}

export const purchaseTypesColors: Record<string, string> = {
  C: '#3864f9',
  P: '#019973',
  V: '#ffb601',
  R: '#00CCFF',
  PC: '#ad5cad',
}

export const paymentTypes: Record<string, string> = {
  CLIENT: 'Cliente',
  TV1: 'TV1'
}

export const phasesOptions: DefaultOptionType[] = [
  { value: '1', label: 'Prospecção' },
  { value: '2', label: 'Orçamento' },
  { value: '3', label: 'Execução' },
  { value: '4', label: 'Fechamento (Financeiro)' },
  { value: '5', label: 'Finalizado' },
  { value: '6', label: 'Cancelado' },
  { value: '7', label: 'Projeto Paralisado' },
]

export const paymentMethodsOptions: DefaultOptionType[] = [
  { value: '1', label: 'Dinheiro' },
  { value: '2', label: 'Cheque' },
  { value: '3', label: 'Cartão de débito' },
  { value: '4', label: 'Cartão de crédito' },
  { value: '5', label: 'Transferência bancária' },
  { value: '6', label: 'Pix' }
]


export const RequestType: Record<string, string> = {
  SIMPLE: 'Requisição Simples',
  REQUISITION_NOT_TIED_A_PROJECT: 'Requisição sem Projeto',
  PURCHASE_PACKAGE: 'Pacote de Compra'
}

export enum PurchaseOrderStatus {
  NEW = 'NEW',
  SEND_TO_ERP = 'SEND_TO_ERP',
  IN_ANALYSIS = 'IN_ANALYSIS',
  PAID_OUT = 'PAID_OUT',
  APPROVED = 'APPROVED',
  REFUSED = 'REFUSED',
  CANCELED = 'CANCELED',
  SUSPEND = 'SUSPEND'
}

export const purchaseOrderStatus: Record<string, string> = {
  NEW: 'Novo',
  SEND_TO_ERP: 'Enviado ao ERP',
  IN_ANALYSIS: 'Em aprovação',
  APPROVED: 'Aprovado',
  REFUSED: 'Reprovada',
  CANCELED: 'Cancelada',
  SUSPEND: 'Suspensa',
  PAID_OUT: 'Pago'
}

export const purchaseOrderStatusColor: Record<string, string> = {
  NEW: '#98EDFE',
  SEND_TO_ERP: 'black',
  IN_ANALYSIS: '#FFF59D',
  APPROVED: '#C8E6C9',
  REFUSED: '#FFCDD2',
  CANCELED: '#E0E0E0',
  SUSPEND: '#FFCDD2',
  PAID_OUT: '#B2DFDB'
}

export const purchaseOrderStatusTextColor: Record<string, string> = {
  NEW: '#031F21',
  SEND_TO_ERP: 'white',
  IN_ANALYSIS: '#805600',
  APPROVED: '#1B5E20',
  REFUSED: '#B71C1C',
  CANCELED: '#424242',
  SUSPEND: '#B71C1C',
  PAID_OUT: '#004D40'
}

export const purchaseOrderStatusTitleColor: Record<string, string> = {
  NEW: '#000000d9',
  SEND_TO_ERP: '#000000d9',
  IN_ANALYSIS: '#000000d9',
  APPROVED: '#000000d9',
  REFUSED: '#B71C1C',
  CANCELED: '#B71C1C',
  SUSPEND: '#B71C1C',
  PAID_OUT: '#000000d9'
}

export const labelBackgroundColor: Record<string, string> = {
  DEFAULT: '#F7F7F7',
  COMPOSED: '#808080',
  IN_ANALYSIS: '#FFF59D',
  APPROVED: '#C8E6C9',
  NOT_APPROVED: '#FFCDD2',
  BLUE: '#B2DFDB'
}

export const labelTextColor: Record<string, string> = {
  DEFAULT: '#404040',
  COMPOSED: '#FFFFFF',
  IN_ANALYSIS: '#805600',
  APPROVED: '#1B5E20',
  NOT_APPROVED: '#B71C1C',
  BLUE: '#004D40'
}

export const purchaseOrderStatusOptions: DefaultOptionType[] = [
  { value: '', label: '' },
  { value: PurchaseOrderStatus.NEW, label: 'Novo' },
  { value: PurchaseOrderStatus.SEND_TO_ERP, label: 'Enviado ao ERP' },
  { value: PurchaseOrderStatus.PAID_OUT, label: 'Pago' },
  { value: PurchaseOrderStatus.IN_ANALYSIS, label: 'Em análise' },
  { value: PurchaseOrderStatus.APPROVED, label: 'Aprovado' },
  { value: PurchaseOrderStatus.REFUSED, label: 'Reprovada' },
  { value: PurchaseOrderStatus.CANCELED, label: 'Cancelado' }
]
export const filterListSupplierOptions: DefaultOptionType[] = [
  { value: '', label: '' },
  { value: SupplierType.SUPPLIER, label: 'Fornecedor' },
  { value: SupplierType.FREELANCE, label: 'Freelancer' },
]

export const analysisSupplierOptions: DefaultOptionType[] = [
  { value: StatusApproveSupplier.NOT_INFORMED, label: 'Não informado' },
  { value: StatusApproveSupplier.APPROVED, label: 'Apto' },
  { value: StatusApproveSupplier.NOT_APPROVED, label: 'Inapto' }
]

export const yesNoOptions: DefaultOptionType[] = [
  { value: YesNo.NO, label: 'Não' },
  { value: YesNo.YES, label: 'Sim' }
]

export enum TypeRequest {
  PURCHASE_PACKAGE = 'PURCHASE_PACKAGE',
  SIMPLE = 'SIMPLE',
  REQUISITION_NOT_TIED_A_PROJECT = 'REQUISITION_NOT_TIED_A_PROJECT'
}

export enum ProductType {
  MC = 'Matéria de consumo',
  MO = 'Mão de obra',
  PA = 'Produto acabado',
  PV = 'Produto veiculo',
  PS = 'Prestação de serviços',
  MA = 'Matérial de produção',
  CE = 'Commodities ou Estratégico'
}

export const acceptedExtensionsFile = [
  '.png',
  '.jpg',
  '.jpeg',
  '.pdf',
  '.bmp',
  '.bmap',
  '.xls',
  '.xlsx',
  '.csv',
  '.ods',
  '.odt',
  '.xps',
  '.txt',
  '.doc'
]
