import styled from 'styled-components'

export const UploadWrapper = styled.span`
  .ant-upload.ant-upload-drag {
    height: 75px;
  }
`
export const RowFormat = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

`;

export const ColFormat = styled.div`
  flex: auto;
  max-width: 24%;
  min-width: 24%;
`;