import styled from 'styled-components'

export const RowFormat = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 18%;
`;

export const Title = styled.h1`
  font-size: 15px;
  font-weight: bold;
  gap: 10px;
`;

export const ColFormat = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;