import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Table,
  Form,
  message,
  Popover,
  Tag,
  Modal
} from "antd";
import { Navigate } from "react-router";
import { Section } from "components";
import {useProject} from "repositories";
import { usePagination, useAuth } from "hooks";
import { formatCurrency } from "utils/helpers";
import moment from "moment";

import { FilterContainer } from "./styles";

const flattenProjectRevisionItems = (data: any) => {

  const flattenedItems: any[] = [];

  data.content.forEach((project: any) => {
    project.projectRevisionItems.forEach((item: any) => {
      const flattenedItem = {
        projectId: project.id,
        projectIdERP: project.idERP,
        projectName: project.name,
        projectClientName: project.clientName,
        ...item
      };
      flattenedItems.push(flattenedItem);
    });
  });

  return flattenedItems;
}

const everyoneHasLines = (projectIds: any[], projectsWithLines: any[]) => {
  const foundIds = new Set();

  for (const project of projectsWithLines) {

    if (projectIds.includes(project.projectId)) {
      foundIds.add(project.projectId);
    }
  }
  for (const id of projectIds) {
    if (!foundIds.has(id)) {
      return true;
    }
  }

  return false;
}

const ProductSelectionRequest = ({
  nextStep,
  prevStep,
  linesSelected,
  onSetKeys,
  onSetLines,
  keys,
  lines,
  product,
  BU,
  projectIds,
  currentProducts,
}: any) => {
  const [clients, setClients] = useState<any>([]);
  const [search, setSearch] = useState("");
  const [requestModal, setRequestModal] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [rowProductSelected, setRowProductSelected] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);

  const { roles: authRoles, hasRole } = useAuth();
  const repositoryProject = useProject();
  const pagination = usePagination();

  const requestsColumns: any = [
    {
      title: "Emissão",
      dataIndex: "issueDate",
      render: (_: any, record: any) => {
        return record.issueDate
          ? moment(record.issueDate).format("DD/MM/YYYY")
          : "";
      },
    },
    {
      title: "Vencimento",
      dataIndex: "maturity",
      render: (_: any, record: any) => {
        return record.maturity
          ? moment(record.maturity).format("DD/MM/YYYY")
          : "";
      },
    },
    {
      title: "Produto",
      dataIndex: "productName",
    },
    {
      title: "Prestador",
      dataIndex: "typeProvider",
      render: (_: any, record: any) => {
        return record.typeProvider === "SUPPLIER" ? "Fornecedor" : "Freelancer";
      },
    },
    {
      title: "Tipo",
      dataIndex: "typeRequest",
      render: (_: any, record: any) => {
        if (record.typeRequest === "SIMPLE") return "Simples";
        return "Composta";
      },
    },
    {
      title: "Categoria",
      dataIndex: "category",
    },
    {
      title: "Alocação",
      dataIndex: "allocation",
    },
    {
      title: "Local",
      dataIndex: "buName",
    },
    {
      title: "Cliente",
      dataIndex: "client",
    },
    {
      title: "Timing",
      dataIndex: "timing",
      render: (_: any, record: any) => {
        if (record.timing === "PLANEJADA")
          return <Tag color="green">Planejada</Tag>;

        return <Tag color="red">Não planejada</Tag>;
      },
    },
    {
      title: "Fatura",
      dataIndex: "paymentType",
    },
    {
      title: "Valor da requisição",
      dataIndex: "totalCost",
      render: (_: any, record: any) => {
        return formatCurrency(record?.totalCost);
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_: any, record: any) => {
        switch (record.status) {
          case "NEW":
            return <Tag color="cyan">Novo</Tag>;
          case "IN_QUOTATION":
            return <Tag color="yellow">Em cotação</Tag>;
          case "APPROVED":
            return <Tag color="green">Aprovado</Tag>;
          case "REFUSED":
            return <Tag color="orange">Reprovada</Tag>;
          case "CANCELED":
            return <Tag color="red">Cancelada</Tag>;
          case "ON_APPROVAL":
            return <Tag color="yellow">Em aprovação</Tag>;
          case "PAID_OUT":
            return <Tag color="green">Pago</Tag>;
          default:
            return record.status;
        }
      },
    },
  ];

  const columns: any = [
    {
      title: "Id do projeto TOTVS",
      dataIndex: "idErp",
    },
    {
      title: "Projeto",
      dataIndex: "projetoDescription",
    },
    {
      title: "Id da linha TOTVS",
      dataIndex: "idLinha",
    },
    {
      title: "Produto",
      dataIndex: "productDescription",
    },
    {
      title: "Tipo do Faturamento",
      dataIndex: "paymentType",
    },
    {
      title: "Escopo da linha",
      dataIndex: "technicalScope",
      key: "technicalScope",
      render: (_: any, record: any) => {
        return (
          <Popover
            content={record.technicalScopeDescription}
            overlayStyle={{ maxWidth: "800px" }}
          >
            {record.technicalScope}
          </Popover>
        );
      },
    },
    {
      title: "Quantidade",
      dataIndex: "amount",
    },
    {
      title: "Diárias",
      dataIndex: "daily",
    },
    {
      title: "Custo Unítario",
      dataIndex: "unitCost",
      render: (_: any, record: any) => {
        return formatCurrency(record.unitCost);
      },
    },
    {
      title: "Custo total",
      dataIndex: "totalCost",
      render: (_: any, record: any) => {
        return formatCurrency(record.totalCost);
      },
    },
    {
      title: "Já tem requisições?",
      dataIndex: "withoutRequest",
      align: "center",
      render: (_: any, record: any) => {
        return record.withoutRequest ? (
          <Tag
            color="green"
            style={{ cursor: "pointer" }}
            onClick={() => openRequests(record.projectRequestItems.filter((item: any) => item.status !== "CANCELED"))}
          >
            Sim
          </Tag>
        ) : (
          <Tag color="default">Não</Tag>
        );
      },
    },
    {
      title: "Requisições",
      dataIndex: "approvalRequests",
      render: (_: any, record: any) => {
        return formatCurrency(record.approvalRequests);
      },
    },
    {
      title: "Saldo da linha",
      dataIndex: "lineBalance",
      render: (_: any, record: any) => {
        return (
          <span style={{ whiteSpace: "nowrap" }}>
            {formatCurrency(record.lineBalance)}
          </span>
        );
      },
    },
  ];

  const openRequests = (requests: any) => {
    setRequestModal(requests);
    setIsModalOpen(true);
  };

  const customError = (error: any) => {
    message.error(error?.response?.data?.message, 10);
    prev();
  }

  const requestItens = async () => {
    const currentPage = pagination.data.current ?? 0;
    const queryParams = new URLSearchParams({
      billingType: BU?.id === 28 ? '' : String(0),
      productId: product.map((obj: any) => obj.id).join(', '),
      buId: String(BU.id),
      page: String(currentPage === 0 ? currentPage : currentPage - 1),
      size: String(10000),
    });

    queryParams.append("projectIds", projectIds?.join(', '));

    const response = await repositoryProject.findProjectItemsByFilter(queryParams, true, customError);
    if (!response) return;


    if (response?.content?.length === 0) {
      message.error("Escolha um projeto com linhas");
      prev();
    }

    const formatedResponse = flattenProjectRevisionItems(response)
    if (everyoneHasLines(projectIds,formatedResponse)) {
      message.error("Um dos projetos selecionados não tem linha com faturamento tv1")
      prevStep()
    }
    let mappedData = formatedResponse.map((request: any) => ({
      projectId: request.projectId,
      BU: BU.name,
      BUId: BU.id,
      idErp: String(request.projectIdERP),
      key: request.id,
      productId: request.productId,
      projectRevisionItemId: request.id,
      projetoDescription: request.projectName,
      idLinha: request.lineIdErp,
      productDescription: request.productDescription,
      costCenterId: request.costCenter.id,
      paymentType: request.billingType === "0" ? "TV1" : "Cliente",
      technicalScope: request.technicalScope ? request.technicalScope?.slice(0, 10) + " ..." : "Inexistente",
      technicalScopeDescription: request.technicalScope ? request.technicalScope : "Inexistente",
      amount: request.amount,
      daily: request.daily,
      unitCost: request.unitCost,
      unitSale: request.unitSale,
      totalCost: request.totalCost,
      withoutRequest: request.existsRequisitions,
      approvalRequests: formatCurrency(
      request.valueApprovedRequisitions
          ? request.valueApprovedRequisitions
          : 0
      ),
      projectRequestItems: request?.projectRequestItems,
      lineBalance: request.valueApprovedRequisitions
          ? request.balanceLine
      : request.totalCost,
      client: request.projectClientName,
      status: request.status
    }));

    setClients(mappedData.filter((data: any) => data.status));

    // if (currentProducts?.length > 0) {
    //   setRowProductSelected(currentProducts);
    //   const mappedKeys = currentProducts.map((product: any) => product.key);
    //   setSelectedRowKeys(mappedKeys);
    // }
  };

  useEffect(() => {
    if (pagination.data.current) requestItens();
  }, [pagination.data.current, search]);

  useEffect(() => {
    if(keys.length) {
      setRowProductSelected(lines);
      setSelectedRowKeys(keys);
    }
  }, [])

  const onSearch = (value: string) => setSearch(value);

  if (authRoles && !hasRole(authRoles?.create_request_simple)) {
    return <Navigate to="/acesso-negado" />;
  }

  const next = () => {
    let containsAllProducts = product.every((product: any) => rowProductSelected.some((obj: any) => obj.productId === product.id))
    if(!containsAllProducts) {
      message.warning('Selecione pelo menos uma linha com cada produto')
      return
    }



    let containsAllIds = projectIds.every( (id:any) => rowProductSelected.some((obj:any) => obj.projectId === id));
    if(!containsAllIds) {
      message.warning('Selecione pelo menos uma linha de cada projeto')
      return
    }else {
      linesSelected(rowProductSelected);
      onSetLines(rowProductSelected);
      onSetKeys(selectedRowKeys);
      nextStep();
    }
  };

  const prev = () => {
    linesSelected();
    prevStep();
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      setRowProductSelected(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  return (
    <>
      <Section>
        <div style={{ marginTop: 24 }}>
          <Form layout="vertical">
            <>
              <FilterContainer>
                <Input.Search
                  placeholder="Pesquise pelo nome"
                  onSearch={onSearch}
                  enterButton
                />
              </FilterContainer>
              <Table
                rowSelection={{
                  type: "checkbox",
                  selectedRowKeys,
                  ...rowSelection,
                }}
                columns={columns}
                dataSource={clients} // datas
                pagination={{ showSizeChanger: true }}
              />

              <Button style={{ margin: "0 4px" }} onClick={() => prev()}>
                Voltar
              </Button>
              <Button
                style={{ margin: "0 4px" }}
                type="primary"
                onClick={() => next()}
              >
                Próximo
              </Button>
            </>
          </Form>
        </div>
      </Section>
      <Modal
        title="Requisições"
        className="horizontal-scrollable-modal"
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        width={1300}
      >
        <Table
          columns={requestsColumns}
          dataSource={requestModal}
          pagination={false}
          style={{ overflowX: "auto" }}
        />
      </Modal>
    </>
  );
};

export default ProductSelectionRequest;
