import React, { useEffect, useState } from 'react'
import {Button, Checkbox, Form, Modal, Pagination} from 'antd'
import { useSuppliers } from 'repositories'

import {
    Box,
    ContentBox,
    ContentRow,
    Content,
    StarsContainer,
    Star,
    ScoreText,
    DateText,
    Card,
    Header,
    Justification,
    Text,
    BarBackground,
    BarContainer,
    ContainerRating,
    RatingText,
    BarFill, ContentRowFilters,ButtonBox
} from "./styles";

import {useFilters, usePagination} from "../../../../hooks";
import {Col, FlexEndContainer, HiddenField, Row, SearchField, SelectField} from "../../../../components";
import {formatDateToServer, formatDateToView, removeMaskCnpj} from "../../../../utils/helpers";
import {DefaultOptionType} from "antd/lib/select";
import FiltersForm from "./Components/FiltersForm";
import {filterByOptions} from "../../../PurchaseRequisition/Components/SupplierRequest/Form";

const formatFilter = (_filters: any) => {
    console.log({_filters})
    if (_filters.startDate) {
        _filters.startDate = formatDateToServer(_filters.startDate);
    }
    if (_filters.endDate) {
        _filters.endDate = formatDateToServer(_filters.endDate);
    }
    return _filters;
};

export const classificationOptions = [
    {
        label: "",
        value: "",
    },
    {
        label: "Maior classificação",
        value: "DESC",
    },
    {
        label: "Menor classificação",
        value: "ASC",
    },
]
export const sortOptions = [
    {
        label: "",
        value: "",
    },
    {
        label: "Mais recentes",
        value: "DESC",
    },
    {
        label: "Mais antigas",
        value: "ASC",
    },
]


type TProps = {
    isModalVisible: boolean
    setIsModalVisible: React.Dispatch<boolean>,
    supplierId: any
}
const EvaluationModal = (props: TProps) => {

    const {
        isModalVisible,
        setIsModalVisible,
        supplierId,
    } = props

    const [evaluations, setEvaluations] = useState<any>({});
    const [isVisibleFilterForm, setIsVisibleFilterForm] = useState(false);
    const [sort, setSort] = useState("");
    const [classification, setClassification] = useState("");

    const [form] = Form.useForm()

    const filters = useFilters();
    const repositorySupplier = useSuppliers()
    const pagination = usePagination();

    const getEvaluation = async () => {
        const currentPage = pagination.data.current ?? 0;
        const _filters = filters.getObjectifiedFilterItems();
        const formatedFilters = formatFilter(_filters);
        let sortFilter, classificationFilter


        const queryParams = new URLSearchParams({
            page: currentPage === 0 ? String(currentPage) : String(currentPage - 1),
            size: String(3),
            ...formatedFilters,
        });
        if(classification.length > 0) {
            classificationFilter = `score,${classification}`
            queryParams.append("sort", classificationFilter);
        }

        if(sort.length > 0) {
            sortFilter = `date,${sort}`
            queryParams.append("sort", sortFilter);
        }

        console.log({sortFilter})

        const response = await repositorySupplier.findSupplierEvaluation(supplierId, queryParams)
        if (!response) return

        pagination.onChange(currentPage,3)
        pagination.changeTotalPages(response?.evaluations?.totalPages);
        setEvaluations({
            ...response,
        })
    }

    useEffect(() => {
      getEvaluation()
    }, [isModalVisible,pagination.data.current, pagination.data.pageSize, filters.items, sort, classification])

    const handleClose = () => {
        form.setFieldValue("sort","");
        form.setFieldValue("classification","");
        setIsModalVisible(false);
    }

    useEffect(() => {
        if (isModalVisible) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [isModalVisible]);

    const ratings = [
        evaluations.countFiveScore,
        evaluations.countFourScore,
        evaluations.countThreeScore,
        evaluations.countTwoScore,
        evaluations.countOneScore,
    ];

    const totalRatings = ratings.reduce((acc, val) => acc + val, 0);

    const onClickOpenFilterForm = () => {
        setIsVisibleFilterForm(true);
    };

    const resetPagination = () => {
        pagination.onChange(1, pagination.data.pageSize ?? 3);
    };

    const onChangeSort = async () => {
        const sort = form.getFieldValue("sort")
        setSort(sort)
    }
    const onChangeClassification = async () => {
        const classification = form.getFieldValue("classification")
        setClassification(classification)
    }

    const initialValues = {
        sort: "",
        classification: "",
    }

    return (
        <Modal
            title="Resumo das avaliações"
            visible={isModalVisible}
            okText=""
            cancelText="Fechar"
            confirmLoading={repositorySupplier.loading}
            // onOk={handleOk}
            onCancel={handleClose}

            width='830px'
        >
            <Form
                form={form}
                initialValues={initialValues}
            >
                <ContentRowFilters>
                    <ButtonBox>
                        <SelectField
                            name='sort'
                            label='Ordenar'
                            options={sortOptions}
                            onChange={onChangeSort}
                        />
                    </ButtonBox>
                    <ButtonBox>
                        <SelectField
                            name='classification'
                            label='Classificação'
                            options={classificationOptions}
                            onChange={onChangeClassification}

                        />
                    </ButtonBox>
                    <ButtonBox>
                        <Button onClick={onClickOpenFilterForm}>Filtros</Button>
                    </ButtonBox>
                </ContentRowFilters>
                <Box>
                    <ContentBox>
                        <Content>
                            <StarsContainer>
                                <ScoreText>{evaluations?.averageScore?.toFixed(2)}</ScoreText>
                                {[...Array(5)].map((_, index) => (
                                    <Star key={index} active={index < evaluations?.averageScore} size={"24px"} />
                                ))}
                            </StarsContainer>
                            <ContainerRating>
                                {ratings.map((count, index) => {
                                    const percentage = totalRatings ? (count / totalRatings) * 100 : 0;
                                    return (
                                        <BarContainer key={index}>
                                            <BarBackground>
                                                <BarFill percentage={percentage} />
                                            </BarBackground>
                                            <RatingText>{5 - index} ☆</RatingText>
                                        </BarContainer>
                                    );
                                })}
                            </ContainerRating>
                        </Content>
                        <Content className="content-spacing">
                            {evaluations?.evaluations?.content.map((evaluation: any) => {
                                // console.log({evaluation})
                                return (
                                    <Card>
                                        <Header>
                                            <StarsContainer>
                                                {[...Array(5)].map((_, index) => (
                                                    <Star key={index} active={index < evaluation?.score} size={"12px"}/>
                                                ))}
                                            </StarsContainer>
                                            <DateText>{formatDateToView(evaluation.date)}</DateText>
                                        </Header>
                                        <ContentRow>
                                            <Text>{evaluation.userName}</Text>
                                            <Text>{evaluation.projectRequest.id}</Text>
                                            <Text>{evaluation.projectRequest.projects[0].projectName}</Text>
                                        </ContentRow>
                                        <br/>
                                        <Justification>{evaluation.justification}</Justification>
                                    </Card>
                                );
                            })}
                        </Content>
                    </ContentBox>
                    <FlexEndContainer className="mt-1">
                        <Pagination
                            onChange={pagination.onChange}
                            {...pagination.data}
                            pageSizeOptions={[3]}
                            current={pagination.data.current}
                            pageSize={pagination.data.pageSize}
                        />
                    </FlexEndContainer>
                </Box>
                <FiltersForm
                    resetPagination={resetPagination}
                    filters={filters}
                    isVisibleDrawer={isVisibleFilterForm}
                    setIsVisibleDrawer={setIsVisibleFilterForm}
                />
            </Form>
        </Modal>

    )
}

export default EvaluationModal