import styled from 'styled-components'
import {FaStar, FaRegStar} from "react-icons/fa";

export const Content = styled.div`
  border-right: ${({ theme }) => theme.borders.SOLID_LIGHT_GRAY};

  &.content-spacing {
    display:flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.MB}) {
    width: 100%;
    border-right: none;
    border-bottom: ${({ theme }) => theme.borders.SOLID_LIGHT_GRAY};
  }
`;

export const Title = styled.label`
  font-weight: bold;
  display: block;
  margin-bottom: 8px;
`;

export const Required = styled.span`
  color: red;
  margin-left: 4px;
`;

export const StarContainer = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 10px;
`;

export const Star = styled(FaStar).attrs<{ selected: boolean, disabled: boolean }>((props) => ({
    color: props.selected ? "#FFD700" : props.disabled ? "#f0f0f0" : "#ddd",
}))<{ selected: boolean, disabled: boolean}>`
  cursor: pointer;
  font-size: 40px;
  transition: color 0.3s;
`;
export const StarDisabled = styled(FaStar).attrs<{ selected: boolean, disabled?: boolean }>((props) => ({
    color: props.selected ? "#FFD700" : "#f0f0f0",
}))<{ selected: boolean }>`
  cursor: pointer;
  font-size: 40px;
  transition: color 0.3s;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 15px;
`;

export const Justification = styled.textarea`
  width: 100%;
  height: 80px;
  border: 1px solid #ccc;
  padding: 8px;
  font-size: 14px;
  resize: none;
`;
