import React, { useState } from "react";
import {
  Section,
} from "components";
import {
  Form,
  Divider, Card, Empty,
} from "antd";

import {StarContainer, Justification, Required, Star, Title, Content, StarDisabled} from "./styles";

const EvaluationForm = (
    props: any,
) => {
  const {order, setOrderEvaluated} = props
  const [form] = Form.useForm<any>();
  const [rating, setRating] = useState<any>(order.score);
  const [disableRating, setDisableRating] = useState<boolean>(order.cantEvaluate);
  const [justification, setJustification] = useState(order.justification);

  const onFinish = async () => {
    const orderEvaluated = {
      ...order,
      justification,
      rating,
      disableRating
    }
    setOrderEvaluated(orderEvaluated);
  };

  const handleStarClick = (index: any) => {
    if (!disableRating) {
      setRating(index + 1);
      form.submit();
    }
  };
  const handleJustification = (justification: any) => {
    setJustification(justification);
    form.submit();
  };
  const handleCheckboxChange = () => {
    setDisableRating(!disableRating);
    if (!disableRating) {
      setRating(null);
    }
    form.submit();
  };


  return (
    <>
      <Section spacedTop>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
        >
          <Content>
            <Title>
              Avalie o prestador <Required>*</Required>
            </Title>
            <StarContainer>
              {[...Array(5)].map((_, index) => (
                  !!order.date ? (
                      <StarDisabled
                          key={index}
                          selected={index < rating}
                          onClick={() => !!order.date ? ()=>{} : handleStarClick(index)}
                      />

                  ) :
                  (
                      <Star
                          key={index}
                          selected={index < rating}
                          disabled={disableRating}
                          onClick={() => !!order.date ? ()=>{} : handleStarClick(index)}
                      />
                  )
              ))}
            </StarContainer>
            <input
                type={"checkbox"}
                name={"notRated"}
                checked={disableRating}
                disabled={!!order.date}
                onChange={handleCheckboxChange}
            />
            <span style={{ marginLeft: '8px'}}>Não consigo avaliar</span>
            <Divider />
            <Title>
              Justifique sua avaliação <Required>*</Required>
            </Title>
            <Justification
                name={"justification"}
                value={justification}
                disabled={!!order.date}
                onChange={(e) => handleJustification(e.target.value)}
                placeholder="Digite sua justificativa aqui..."
            />
          </Content>
        </Form>
      </Section>
    </>
  );
};

export default EvaluationForm;
