import React, { useEffect, useMemo, useState } from "react";
import {Badge, List, Row, Tooltip} from "antd";
import { IoIosArrowDown, IoIosArrowBack } from "react-icons/io";
import {TApprovalHierarchyUser, TPurchaseOrder} from "repositories";
import { formatCurrency } from "utils/helpers";
import { useAuth } from "hooks";
import {Container, Header, Content} from "./styles";
import {BiCommentDetail} from "react-icons/bi";

type TApprover = Pick<TApprovalHierarchyUser, "userName"> & {
  minimumValue: string;
  isApproved: boolean;
  isCurrent: boolean;
  approveAnswer: any;
};

type TProps = {
  order: TPurchaseOrder;
};

const UserHierarchy = ({ order }: TProps) => {
  const { approvers } = order;

  const [isOpen, setIsOpen] = useState(true);
  
  const { userData } = useAuth();

  const project_status : string = order.status;
  
  const onSort = (list: any | undefined) => {
    if ( list === undefined) return [];
    if ( !list.length) return [];

    return list.sort((old: any, after: any) => {
      if (old.approveSequence > after.approveSequence) return 1;
      if (old.approveSequence < after.approveSequence) return -1;

      return 0;
    });
  };

  const formattedApprovers = useMemo(() => {
    if (isOpen) {
      const ordenedApprovers = onSort(approvers);
      const currentUser = ordenedApprovers.find(
        (find: any) => find.userEmail === userData?.userEmail
      );
      //if (!currentUser) return [] as TApprover[];

      return ordenedApprovers.map((approver: any) => {
        const isCurrent = currentUser?.userId === approver.userId;
        const formattedMinimumValue = formatCurrency(
          approver.minimumValue,
          false
        );
        return {
          userName: approver.userName,
          minimumValue: `Valor mínimo de ${formattedMinimumValue}`,
          isApproved: approver.approveAnswer?.answer === "APPROVED" ? true : false,
          isCurrent,
          approveAnswer: approver.approveAnswer,
          replaced: approver.replaced
        };
      });
    }

    return [];
  }, [approvers, userData, isOpen]);

  const handleOpen = () => setIsOpen(!isOpen);

  const StatusApprover = ({ approver }: any) => {
    switch (approver) {
      case "APPROVED":
        return <Badge status="success" />;
      case "REFUSED":
        return <Badge status="error" />;
      default:
        return <Badge status="warning" />;
    }
  };

  const StatusApproverClass = (approver : any) => {
    switch (approver) {
      case "APPROVED":
        return "success";
      case "REFUSED":
        return "error";
      default:
        return "warning";
    }
  };

  function getUserNames(obj: any) {
    let result = obj.userName;

    function traverse(obj: any) {
        if (obj.userName) {
            result += ' - substituindo ' + obj.userName;
        }
        if (obj.replaced) {
            traverse(obj.replaced);
        }
    }

    traverse(obj.replaced);
    return result.trim();
}

  return (
    <Container>
      <Header >
      {/* <Header onClick={handleOpen}> */}
        <span>Aprovadores</span>
        {/* {isOpen ? <IoIosArrowDown /> : <IoIosArrowBack />} */}
      </Header>
      {/* {isOpen && ( */}
        <Content>
          <List size="small" className={(project_status == 'REFUSED') ? ' disabled' : ''}>
            {formattedApprovers.map((approver: any) => (
              <List.Item key={approver.userName}>
                <List.Item.Meta
                  title={
                    <div>
                      <Row>
                        <span 
                          className={StatusApproverClass(approver?.approveAnswer?.answer)}
                          >
                            {
                              approver?.replaced ?
                                <Tooltip title={getUserNames(approver)}>
                                  {approver.userName} - Aprovador Substituto
                                </Tooltip>
                              :
                              <>{approver.userName}</>
                            }
                        </span> {/* - {"  "} */}
                        <div className="ant-commentary">
                          <span>{approver.minimumValue}</span>
                          {approver.approveAnswer?.observation && (
                              <Tooltip title={approver.approveAnswer?.observation}>
                                {/* <BiCommentDetail /> */}
                                <img
                                  src="/notify.svg"
                                  alt=""
                                  width="16px"
                                  style={{ marginTop: "0px", marginLeft: "-5px" }}
                                  className={StatusApproverClass(approver?.approveAnswer?.answer)}
                                />
                              </Tooltip>
                          )}
                        </div>
                      </Row>

                    </div>

                  }
                />

                <StatusApprover approver={approver?.approveAnswer?.answer} />

                {/* {approver.isApproved && <Badge status="success" />}
                {(!approver.isApproved || approver.isCurrent) && (
                  <Badge status="warning" />
                )} */}
              </List.Item>
            ))}
          </List>
        </Content>
      {/* )} */}
    </Container>
  );
};

export default UserHierarchy;
