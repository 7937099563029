import styled from "styled-components";
import { FaStar } from "react-icons/fa";

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.WHITE};
  border: ${({ theme }) => theme.borders.SOLID_LIGHT_GRAY};
  padding: 0px 0px 6px;
  margin: 5px 0;

  > div {
    padding: 5px 15px;
  }

  > div:last-child {
    border-right: 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.MB}) {
    display: flex;
    flex-direction: column;
  }
`;

export const ContentBox = styled.div`
  display: grid;
  grid-template-columns: 55% 1fr;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.WHITE};
  padding: 6px 0;
  margin: 5px 0;

  > div {
    padding: 5px 15px;
  }

  > div:last-child {
    border-right: 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.MB}) {
    display: flex;
    flex-direction: column;
  }
`;

export const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
export const ContentRowFilters = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: initial;
  gap: 16px;
`;
export const ButtonBox = styled.div`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

export const Content = styled.div`
  border-right: ${({ theme }) => theme.borders.SOLID_LIGHT_GRAY};

  &.content-spacing {
    display:flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.MB}) {
    width: 100%;
    border-right: none;
    border-bottom: ${({ theme }) => theme.borders.SOLID_LIGHT_GRAY};
  }
`;

export const StarsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

interface StarProps {
    active: boolean;
    size: string;
}

export const Star = styled(FaStar)<StarProps>`
  color: ${({ active }) => (active ? "#FFD700" : "#ccc")};
  font-size: ${({ size }) => (size)};
`;

export const ScoreText = styled.span`
  font-size: 20px;
  font-weight: bold;
`;


export const Card = styled.div`
  border: 1px solid #ddd;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 24rem;
  background-color: white;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;


export const DateText = styled.span`
  color: gray;
  font-size: 0.875rem;
`;

export const Text = styled.div`
  margin-top: 8px;
  font-weight: bold;
  font-size: 12px;
`;

export const Justification = styled.text`
  width: 100%;
  height: 80px;
  //border: 1px solid #ccc;
  padding: 8px;
  font-size: 14px;
  resize: none;
`;

export const ContainerRating = styled.div`
  width: 28rem;
`;

export const BarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
  margin-top: 15px;
`;

export const BarBackground = styled.div`
  width: 100%;
  background-color: #d1d5db;
  height: 0.75rem;
  border-radius: 0.375rem;
  overflow: hidden;
`;

interface BarFillProps {
    percentage: number;
}

export const BarFill = styled.div<BarFillProps>`
  background-color: #facc15;
  height: 100%;
  width: ${(props) => props.percentage}%;
`;

export const RatingText = styled.span`
  font-size: 12px;
  white-space: nowrap;

`;