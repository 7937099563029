import { useState } from 'react'
import useRest from 'hooks/useRest'
import { TBank } from 'repositories'

type TFilterBankParams = TPaginationRequest & Partial<TBank>

type TBankResponse = TPaginationResponse & {
  content: TBank[]
}

const useBanks = () => {
  const [loading, setLoading] = useState(false)
  const rest = useRest()

  const findBanksByFilter = async (
    params: any,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/supplier/bank-account/bank'
    const response = await rest.get(url, params)
    if (triggerLoading) setLoading(false)
    if (!response) return {} as TBankResponse

    return response.result as TBankResponse
  }

  return {
    loading,
    findBanksByFilter
  }
}

export default useBanks
