import React, { useEffect, useMemo, useState } from "react";
import {
  TUserArea,
  TUserForm,
  TUserFunction,
  TUserRequest,
  useCommon,
  useUserGroups,
  useUsers,
} from "repositories";
import {
  Section,
  PageHeader,
  TextField,
  Col,
  Row,
  SelectField,
  CheckField,
  HiddenField,
  DateField,
} from "components";
import { Navigate, useLocation, useNavigate, useParams } from "react-router";
import { Button, Form, message, Space } from "antd";
import { Status, UserStatus } from "utils/constants";
import { DefaultOptionType } from "antd/lib/select";
import moment from "moment";
import { useAuth } from "hooks";
import {ColFormat, RowFormat, Title} from "./styles";

const initialValues = {
  id: 0,
  name: "",
  email: "",
  picture: "",
  telephone: "",
  userGroupId: 0,
  userAreaId: 0,
  userFunctionId: 0,
  supplierId: null,
  status: UserStatus.ACTIVATED,
};

const convertToOptions = (items: TUserArea[] | TUserFunction[]) =>
  items.map(
    (item) =>
      ({
        label: item.name,
        value: item.id,
      } as DefaultOptionType)
  );

const UserForm = () => {
  const [user, setUser] = useState<TUserForm>({} as TUserForm);
  const [groupOptions, setGroupOptions] = useState<DefaultOptionType[]>([]);
  const [areaOptions, setAreaOptions] = useState<DefaultOptionType[]>([]);
  const [functionOptions, setFunctionOptions] = useState<DefaultOptionType[]>(
    []
  );
  const [temporaryAbsenteeOptions, setTemporaryAbsentee] = useState<
    DefaultOptionType[]
  >([]);

  const { roles: authRoles, hasRole } = useAuth();
  const d = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const repository = useUsers();
  const userGroupRepository = useUserGroups();
  const commonRepository = useCommon();
  const [form] = Form.useForm<TUserForm>();

  const [visibleDates, setVisibleDates] = useState(false);

  // useEffect(() => {
  //   console.warn
  // }, [user])
  const getUsers = async (userFunctionId: any) => {
    const params: any = {
      page: null,
      size: 1000,
      // SOLUÇÂO PALIATIVA PARA PROBLEMA DE SUBSTITUIÇÂO SOLICITADA POR BRUNO ROCHA
      // userFunctionId: userFunctionId,
      activityApprover: true,
      temporaryAbsentee: false,
    };
    const r = await repository.findByFilterUsers(params);
    if (!r) return;
    const mappedUsers = r.content.filter(
      (user: any) => user.approver && user.id !== form.getFieldValue("id")
    );
    const _mappedUsers = mappedUsers.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    const _temporaryAbsentee = convertToOptions(_mappedUsers);
    setTemporaryAbsentee(_temporaryAbsentee);
    // console.log(d);
  };

  useEffect(() => {
    const getUser = async () => {
      if (!id) return;
      const response = await repository.findUserById(Number(id));
      getUsers(response.userFunction?.id || null);
      if (!response) return;

      // console.log(response);

      const data = {
        id: response.id,
        name: response.name,
        email: response.email,
        picture: response.picture,
        telephone: response.telephone,
        userGroupId: response.userGroup?.id || 0,
        userAreaId: response.userArea?.id || null,
        userFunctionId: response.userFunction?.id || null,
        supplierId: response.supplierId,
        status: response.status,
        approver: response.approver,
        temporaryAbsentee: response.temporaryAbsentee,
        periodAbsenceFrom: response.periodAbsenceFrom
          ? [
              moment(response.periodAbsenceFrom),
              moment(response.periodAbsenceUntil),
            ]
          : "",
        // periodAbsenceUntil: response.periodAbsenceUntil
        //   ? moment(response.periodAbsenceUntil)
        //   : "",
        substituteApprover: response.substituteApprover,
        disableSupplierEvaluationNotification: response.disableSupplierEvaluationNotification
      };
      setVisibleDates(response.temporaryAbsentee);
      form.setFieldsValue(data);
    };

    const requestUserGroups = async () => {
      const params = { size: 1000, page: 0, groupStatus: Status.ACTIVE };
      const response = await userGroupRepository.findGroupsByFilter(params);
      if (!response) return;

      const userGroups = convertToOptions(response.content);
      setGroupOptions(userGroups);
    };

    const getUserFunctionsAndArea = async () => {
      const [userArea, userFunction] = await Promise.all([
        commonRepository.findUserAreaByFilter(),
        commonRepository.findUserFunctionByFilter(),
      ]);

      const _userArea = convertToOptions(userArea);
      const _userFunction = convertToOptions(userFunction);

      setAreaOptions(_userArea);
      setFunctionOptions(_userFunction);
    };

    form.resetFields();
    requestUserGroups();
    getUserFunctionsAndArea();
    getUser();
  }, [id]);

  function changeAusense() {
    const approver = form.getFieldValue("approver");
    const temporary = form.getFieldValue("temporaryAbsentee");
    if (approver && temporary) {
      setVisibleDates(true);
    } else {
      setVisibleDates(false);
    }
  }

  const onFinish = async (values: any) => {
    const data: any = {
      ...values,
      id: values.id,
      userGroupId: values.userGroupId || null,
      periodAbsenceFrom: values.periodAbsenceFrom
        ? values.periodAbsenceFrom[0]
        : "",
      periodAbsenceUntil: values.periodAbsenceFrom
        ? values.periodAbsenceFrom[1]
        : "",
      substituteApprover: values.substituteApprover,
      temporaryAbsentee: values.temporaryAbsentee,
      disableSupplierEvaluationNotification: values.disableSupplierEvaluationNotification
    };

    try {
      await repository.updateUser(data);
      navigate("/usuarios");
      message.success("Usuário atualizado com sucesso!");
    } catch (error) {}
  };

  const onClickGoBack = () => {
    navigate("/usuarios");
  };

  const { title, isView } = useMemo(() => {
    const [, , page] = pathname.split("/");
    const getTitle = () => {
      if (page === "visualizar") return "Visualizar";
      if (id) return "Editar";

      return "Novo";
    };

    return {
      title: getTitle(),
      isView: page === "visualizar",
    };
  }, [id]);

  if (authRoles && !hasRole(authRoles?.user_view || authRoles?.user_edit)) {
    return <Navigate to="/acesso-negado" />;
  }

  const disabledDate = (current: any) => {
    return current && current < moment().startOf("day");
  };

  return (
    <>
      <PageHeader
        title={`${title} Usuário`}
        breadcrumbItems={["Usuários", title, String(id)]}
      />
      <Section spacedTop>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={initialValues}
        >
          <Row>
            <Col xl={8} xxl={8} md={8} xs={8}>
              <HiddenField name="id" />
              <TextField disabled={true} name="name" label="Nome Completo" />
            </Col>
            <Col xl={8} xxl={8} md={8} xs={8}>
              <TextField
                // disabled={isView || !authRoles?.role_admin}
                disabled
                name="email"
                label="Email"
              />
            </Col>
          </Row>
          <Row>
            <Col xl={8} xxl={8} md={8} xs={8}>
              <TextField
                disabled={isView || !authRoles?.role_admin}
                name="telephone"
                label="Telefone"
              />
            </Col>
            <Col xl={8} xxl={8} md={8} xs={8}>
              <SelectField
                name="userGroupId"
                label="Grupo"
                disabled={isView || !authRoles?.role_admin}
                options={groupOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col xl={8} xxl={8} md={8} xs={8}>
              <SelectField
                name="userFunctionId"
                label="Função"
                disabled={true}
                options={functionOptions}
              />
            </Col>
            <Col xl={8} xxl={8} md={8} xs={8}>
              <SelectField
                name="userAreaId"
                label="Area"
                disabled={true}
                options={areaOptions}
              />
            </Col>
          </Row>
          <RowFormat>
            <CheckField
                name="approver"
                label="Aprovador"
                disabled={isView || !authRoles?.role_admin}
                onChange={changeAusense}
            />

            <CheckField
                name="status"
                disabled={isView || !authRoles?.role_admin}
                label="Ativo?"
            />
          </RowFormat>
          <Row>
            <ColFormat>
              <Title>Aplicar ausência temporária</Title>
              <CheckField
                name="temporaryAbsentee"
                label="Ausente temporariamente"
                disabled={isView || !authRoles?.role_admin}
                onChange={changeAusense}
              />
            </ColFormat>
          </Row>
          <Row>
            <ColFormat>
              <Title>Notificações</Title>
              <CheckField
                name="disableSupplierEvaluationNotification"
                label="Desativar disparos de avaliações"
                disabled={isView || !authRoles?.role_admin}
                onChange={changeAusense}
              />
            </ColFormat>
          </Row>
          {visibleDates ? (
            <>
              <Row>
                <Col xl={8} xxl={8} md={8} xs={8}>
                  <DateField
                    name="periodAbsenceFrom"
                    label="Período de ausência"
                    disabled={isView || !authRoles?.role_admin}
                    disabledDate={disabledDate}
                    required
                    rangePicker
                    rules={[
                      {
                        required: true,
                        message: "Selecione o período",
                      },
                    ]}
                  />
                </Col>
              </Row>
              <Row>
                <Col xl={8} xxl={8} md={8} xs={8}>
                  <SelectField
                    name="substituteApprover"
                    label="Substituto para alçada"
                    disabled={isView || !authRoles?.role_admin}
                    options={temporaryAbsenteeOptions}
                    required
                    rules={[
                      {
                        required: true,
                        message: "Selecione o substituto",
                      },
                    ]}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}
          <Form.Item>
            <Space size="small">
              <Button onClick={onClickGoBack} htmlType="button">
                Voltar
              </Button>
              <Button
                loading={repository.loading}
                disabled={isView || repository.loading}
                type="primary"
                htmlType="submit"
              >
                Salvar
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Section>
    </>
  );
};

export default UserForm;
