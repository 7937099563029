import useRest from "hooks/useRest";
import { useState } from "react";

const useEvaluation = () => {
  const [loading, setLoading] = useState(false);
  const rest = useRest();

  const createEvaluation = async (params: any, triggerLoading = true) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/manager/supplier-evaluation/register";
    const response = await rest.put(url, params);
    if (triggerLoading) setLoading(false);
    if (!response) return undefined;

    return response.result;
  };

  const findEvaluation = async (
      params: any,
      triggerLoading = true) => {
    if (triggerLoading) setLoading(true);
    const url = `/v1/manager/supplier-evaluation`;
    const response = await rest.get(url, params);
    if (triggerLoading) setLoading(false);
    if (!response) return undefined;

    return response.result;
  };

  return {
    loading,
    findEvaluation,
    createEvaluation,
  };
};

export default useEvaluation;
