import React from "react";
import parse from "html-react-parser";
import { Tooltip, Popover, Badge } from "antd";
import { TTimeline } from "repositories";
import { BiCommentDetail, BiInfoCircle } from "react-icons/bi";
import { formatDateToView, formatCurrency } from "utils/helpers";
import { Container, Item, Items, TimelineContainer } from "./styles";
import moment from "moment";

type TProps = {
  items: TTimeline[];
};

const timelineItemNames: Record<string, string> = {
  RECEIVED_BY_PURCHASE: "received<br />por compras",
  IN_QUOTATION: "Em<br />cotação",
  FORWARDED_TO_HIERARCHY: "Encaminhado<br />para alçada",
  ANSWERED_BY_FIRST_APPROVER: "1°<br />alçada",
  ANSWERED_BY_SECOND_APPROVER: "2°<br />alçada",
  ANSWERED_BY_THIRD_APPROVER: "3°<br />alçada",
  ANSWERED_BY_FOURTH_APPROVER: "4°<br />alçada",
  ANSWERED_BY_FIFTH_APPROVER: "5°<br />alçada",
  ANSWERED_BY_SIXTH_APPROVER: "6°<br />alçada",
  ANSWERED_BY_SEVENTH_APPROVER: "7°<br />alçada",
  FORWARDED_TO_ELAW: "Encaminhado<br />para Elaw",
  SIGNED_CONTRACT: "Contrato<br />assinado",
  APPROVED_AWAITING_INVOICE: "Aprovado<br />esperando NF",
  INVOICE_RECEIVED: "NF<br />recebida",
  PAID_INVOICE: "NF<br />paga",
};

const timelineStatusIconsNColors: Record<
  string,
  { symbol: string; color: string }
> = {
  NOT_DEFINED: { symbol: "", color: "#4e4e4e" },
  ACCEPT: { symbol: "V", color: "#048d03" },
  APPROVED: { symbol: "V", color: "#048d03" },
  REFUSED: { symbol: "X", color: "red" },
  CURRENT: { symbol: "?", color: "#ed8306" },
  STEP_IS_NOT_REQUIRED: { symbol: "NA", color: "#4e4e4e" },
};

const Timeline = (props: any) => {

  const { order } = props;

  const items = order?.timeline

  const columnsInstallment: any = []

  const sortedItems = {
    ...order,
    items: order?.items?.map((item: any) => ({
      ...item,
      apportions: item?.apportions?.sort((a: any, b: any) => {
        const dateA: any = new Date(a.dueDate);
        const dateB: any = new Date(b.dueDate);
        return dateA - dateB;
      })
    }))
  }

  sortedItems?.items[0]?.apportions?.forEach((apportion: any, index: any) => {

    let totalInstallmentsValue = 0

    sortedItems?.items?.forEach((item: any) => {
      totalInstallmentsValue += item?.apportions[index]?.value
    })

    let received = {}

    let paidOut = {}

    // RECEIVED

    // CANCELED

    // RECEIVED -> CANCELED

    // RECEIVED -> PAID_OUT

    // RECEIVED -> PAID_OUT -> CANCELED

    if(apportion?.status?.length === 1) {
      if(apportion?.status[0]?.statusNF === "CANCELED") {
        received = {
          dueDate: apportion?.status[0]?.dueDate,
          statusNF: "CANCELED"
        }
        paidOut = {
          dueDate: "",
          statusNF: ""
        }
      } else {
        received = {
          dueDate: apportion?.status[0]?.dueDate,
          statusNF: "RECEIVED"
        }

        paidOut = {
          dueDate: "",
          statusNF: "RECEIVED"
        }
      }
    }else if(apportion?.status?.length === 2) {
      if(apportion?.status?.find((item: any) => item.statusNF === "CANCELED")) {
        const can = apportion?.status?.find((item: any) => item.statusNF === "CANCELED")
        received = {
          dueDate: can?.dueDate,
          statusNF: "CANCELED"
        }
        paidOut = {
          dueDate: '',
          statusNF: "RECEIVED"
        }
      } else {
        const pay = apportion?.status?.find((item: any) => item.statusNF === "PAID_OUT")
        const rec = apportion?.status?.find((item: any) => item.statusNF === "RECEIVED")
        received = {
          dueDate: rec?.dueDate,
          statusNF: "RECEIVED"
        }

        paidOut = {
          dueDate: pay?.dueDate,
          statusNF: "PAID_OUT"
        }
      }
    }else if (apportion?.status?.length >= 3) {
      const can = apportion?.status?.find((item: any) => item.statusNF === "CANCELED")
      const rec = apportion?.status?.find((item: any) => item.statusNF === "RECEIVED")
      received = {
        dueDate: rec?.dueDate,
        statusNF: "RECEIVED"
      }
      paidOut = {
        dueDate: can?.dueDate,
        statusNF: "CANCELED"
      }
    }

    columnsInstallment.push({
      installmentDate: moment(apportion.dueDate).format('DD/MM/YYYY'),
      received: received,
      paidOut: paidOut,
      totalInstallmentsValue,
    });

  });

  const getDate = (date: string) => {
    if (!date) return "";
    return formatDateToView(date);
  };

  const getColor = (status: string) => {
    if (!status) return "#dedcdc";
    return timelineStatusIconsNColors[status].color;
  };

  function iniciais(str: string) {
    let palavras = str.split(" ");
    let iniciais = palavras.slice(0, 2).map(function (palavra) {
      return palavra.charAt(0);
    });
    let resultado = iniciais.join("");
    return resultado;
  }

  const getSymbol = (status: string, responsibleName: string) => {
    if (!status) return "";
    // if (status === "ACCEPT" || status === "APPROVED") {
    //   return <div>{responsibleName && responsibleName.charAt(0)}</div>;
    // }
    // return timelineStatusIconsNColors[status].symbol;
    if (responsibleName) {
      return <div>{responsibleName && iniciais(responsibleName)}</div>;
    }
    return timelineStatusIconsNColors[status].symbol;
  };

  const StatusApprover = ({ status }: any) => {
    const styleBadge = {
      margin: '0 10px'
    }
    switch (status) {
      case "RECEIVED":
        return <Badge status="success" style={styleBadge} />;
      case "CANCELED":
        return <Badge status="error" style={styleBadge} />;
      default:
        return <Badge status="warning" style={styleBadge} />;
    }
  };

  const StatusPaga = ({ status }: any) => {
    const styleBadge = {
      margin: '0 10px'
    }
    switch (status) {
      case "PAID_OUT":
        return <Badge status="success" style={styleBadge} />;
      case "CANCELED":
        return <Badge status="error" style={styleBadge} />;
      default:
        return <Badge status="warning" style={styleBadge} />;;
    }
  };

  function getUserNames(obj: any) {
    let result = obj.responsibleName;

    function traverse(obj: any) {
        if (obj.userName) {
            result += ' - substituindo ' + obj.userName;
        }
        if (obj.replaced) {
            traverse(obj.replaced);
        }
    }

    traverse(obj.replaced);
    return result.trim();
  }

  const titleTooltip = (item: any) => {
    if(item.replaced){
      return <>
        <span>{item?.responsibleName}<br/>Substituto: {item?.replaced?.userName}</span>
      </>
    }
    return item?.responsibleName
  }

  return (
    <Container>
      <TimelineContainer>
        <Items>
          {items.map((item: any) => {
            const formattedTitle = parse(timelineItemNames[item.type] ?? "");
            return (
              <Item color={getColor(item.status)} key={item.id}>
                <p>{formattedTitle}</p>
                <div>
                  <Tooltip title={item?.replaced ? getUserNames(item) : item?.responsibleName}>
                    {getSymbol(item.status, item?.responsibleName)}
                  </Tooltip>
                </div>
                <span>{getDate(item.createdAt)}</span>
                {item.approveAnswer && (
                  <Tooltip title={item.approveAnswer.observation}>
                    <BiCommentDetail />
                  </Tooltip>
                )}
                {
                  item?.type === 'INVOICE_RECEIVED' &&
                  <Popover
                    placement="bottomLeft"
                    content={
                      columnsInstallment.map((installment: any) => (
                        <>
                          <span>
                            {installment?.installmentDate} - {formatCurrency(installment?.totalInstallmentsValue)} 
                            <StatusApprover status={installment?.received?.statusNF} /> <span style={{display: 'inline-block', width: '80px'}} >{installment?.received?.statusNF === "CANCELED" ? 'Cancelada: ' : 'Recebida: '}</span>{installment?.received?.dueDate ? moment(installment?.received?.dueDate).format('DD/MM/YYYY') : '__/__/____' }</span>
                          <br></br>
                        </>
                      ))
                    }
                    >
                    <BiInfoCircle />
                  </Popover>
                }
                {
                  item?.type === 'PAID_INVOICE' &&
                  <Popover
                    placement="bottomLeft"
                    content={
                      columnsInstallment.map((installment: any) => (
                        <>
                         <span>{installment?.installmentDate} - {formatCurrency(installment?.totalInstallmentsValue)} <StatusPaga status={installment?.paidOut?.statusNF} /> <span style={{display: 'inline-block', width: '85px'}} >{installment?.paidOut?.statusNF === "CANCELED" ? 'Cancelado: ' : 'Pagamento: '}</span>{installment?.paidOut?.dueDate ? moment(installment?.paidOut?.dueDate).format('DD/MM/YYYY') : '__/__/____'}</span>
                         <br></br>
                        </>
                      ))
                    }
                    >
                    <BiInfoCircle />
                  </Popover>
                }
              </Item>
            );
          })}
        </Items>
      </TimelineContainer>
    </Container>
  );
};

export default Timeline;
